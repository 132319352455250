import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Layout, Seo} from '@app/components';

import image from '@app/assets/images/error-face.webp';

const NotFound: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.404',
  };
  const {t} = useLocale();

  return (
    <Layout>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="bg-error-page bg-center bg-cover bg-no-repeat">
        <div className="container mx-auto px-4 pt-40 pb-40 lg:pb-80 lg:px-48 xl:max-w-container-xl text-center">
          <div className="flex justify-center mb-7">
            <img src={image} alt={t('labels.title', SCOPE_OPTIONS)} />
          </div>
          <h1 className="text-8xl font-bold text-black">
            {t('labels.title', SCOPE_OPTIONS)}
          </h1>
          <p className="mt-4 text-gray-500 text-base">
            {t('labels.description', SCOPE_OPTIONS)}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
